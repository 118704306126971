<template>
<div>
  practice
</div>
</template>

<script>
export default {
name: "Practice"
}
</script>

<style scoped>

</style>